<template>
  <div class="dash-font">
    <header></header>
    <!-- 페이지시작 -->
    <section class="cover">
      <div class="cover_img_adult">
          <ul class="cover_psn_d">
            <li><span>Sample ID :</span> {{ sample.code }}<span>고객명 :</span> {{ sample.name }}<span>성별 :</span> {{ sample.sex }}</li>
            <li><span>발급일 :</span> {{ moment(new Date()).format('YYYY년 M월 D일') }} <span>발급처 :</span> AiBiotics<span>문의처 :</span> 070.7703.0380 </li>
          </ul>
      </div>
    </section>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <section class="full">
    </section>
    <!-- 페이지끝 -->
    <div style="height: 180px"></div>

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_microbiome_explain.png" alt="microbiome"><span class="miso"></span></h2>
    </div>
    <section>
      <ul class="microbiome_txt">
        <li>
          <div>
            <strong>Microbiome 확인 하는 이유</strong>
            <span>
					인간의 몸에는 인간과 공생하는 또 다른 생명들이 존재합니다.<br>
					바로 ‘미생물’입니다.<br>
					인체에 존재하는 미생물의 90% 이상이 장(Gut)에 존재하며, 이 미생물의 총 무게는1.5~2Kg 정도로 인간 몸무게의 2~4%를 차지할 정도로 많고 다양합니다.
					특히 장에 사는 미생물들은 인간의 질병 90%와 관련이 있고, 면역 기능 중 70%를 담당하고 있어 면역과 소화에 절대적인 역할을 하고 있습니다.<br>
					따라서 미생물이 인체에 미치는 영향은 매우 크기 때문에 제 2의 유전자인 장 미생물 분석이 필요합니다.
					</span>
          </div>
        </li>
        <li class="microtxt_adult">
          <div>
            <strong>장은 면역의 중심</strong>
            <span>
					한국인의 성장기에는 피자 햄버거 같은 다양한 서양 음식이 아이들을 유혹합니다. 성인이 되어서는 더 다양하고 자극적인 음식들이 주위에 넘쳐 납니다. 맛있는 음식들은 우리의 입을 즐겁게 하고 삶을 윤택하게 하지만 동시에 장에는 큰 시련을 주고 있습니다. 불과 수십년만에 서구화 된 우리 식단은 수 천년간 우리 음식에 길들여진 한국인의 장에는 큰 시련이 되고 있습니다.
					당뇨병, 대장암 같은 성인병이 급격히 증가하는 이유는 대부분 음식과 운동입니다. 그 음식이 인체에 영향을 주는 장소가 장이며 장에사는 미생물들이 아주 중요한 원인과 결과가 되는 것입니다.<br>
					장내 미생물은 중요한 건강의 지표가 되고 있으며 미래에 올 수 있는 여러 건강 문제를 미리 알 수 있는 열쇠가 됩니다.


					</span>
          </div>
        </li>
        <li>
          <div>
            <strong>인체는 생태계</strong>
            <span>
					인간의 몸은 하나의 생태계와 같습니다.<br>
					모든 곤충이 유해하거나 모든 포유류가 유익하지 않는 것처럼 체내의 미생물 역시 유익균과 유해균의 이분법으로 분류하는 방법도 정답은 아닙니다.<br>
					각 사람마다 인체 생태계 균형을 위하여 AIBiotics는 맞춤형 ‘건강 균형‘을 찾아가는 방법을 제시합니다.
					</span>
          </div>
        </li>
      </ul>
      <div class="tip_box txt_summary">
			<span>장내 미생물 검사에 의한 미생물 검출이 질병을 의미하지는 않습니다. 또한 생물 검출 실패는 질병을 일으키는 미생물의 존재를 배제하지
			않습니다. 이 검사에 의해 검출되지 않는 다른 유기체도 존재할 수 있습니다. 이 검사는 미생물 또는 그 항균 민감도를 확인하는 확립 된
			방법을 대체하지 않습니다. 건강 상태에 설명 된 박테리아와 결과는 가능한 문제의 징후만을 제공합니다. 이것은 진단이 아니며 그렇게
			간주될 수 없습니다. 그러나 미래에 문제가 될 수 있는 가능성은 설명이 가능할 수도 있습니다.</span>
      </div>
    </section>
    <div class="footer" style="margin-bottom:20px;">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>1</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->
    <section class="full">
      <ul class="index_list">
        <li></li>
        <li>
          <ol>
            <li>
              <span>01</span>
              <strong> Introduction</strong>
            </li>
            <li>
              <span>02</span>
              <strong> 종합 평가</strong>
            </li>
            <li>
              <span>03</span>
              <strong> Balance 평가</strong>
              <ul>
                <li>ㆍ3.1 Balance 1 : 비만</li>
                <li>ㆍ3.2 Balance 2 : 식성 /식이취향</li>
                <li>ㆍ3.3 Balance 3 : 운동</li>
                <li>ㆍ3.4 Balance 4 : 수면,음주흡연,감성</li>
                <li>ㆍ3.4 Balance 5 : 자가건강,가족력</li>
                <li>ㆍ3.5 Balance 6 : 장내 미생물</li>
              </ul>
            </li>
            <li>
              <span>04</span>
              <strong> 다양성</strong>
            </li>
            <li>
              <span>05</span>
              <strong> Phylum (문)분석</strong>
            </li>
            <li>
              <span>06</span>
              <strong> Entero Type (장유형)분석</strong>
            </li>
            <li>
              <span>07</span>
              <strong> 유산균 분석</strong>
            </li>
            <li>
              <span>08</span>
              <strong> 장, 면역 세부지표</strong>
            </li>
            <li>
              <span>09</span>
              <strong> Summary</strong>
            </li>
          </ol>
        </li>
      </ul>
    </section>
    <!-- 페이지시작 -->
    <div style="height: 100px"></div>
    <!-- 페이지시작 -->
    <section class="header_none">
      <div class="star_graph">
        <v-chart :options="starChart" :initOptions="{
            width: 1100, height : 500 }" theme='shine'/>
      </div>
      <div class="balance_index">
        <ul>
          <li>AiB Health Balance Index</li>
          <li :class="bIndexClass"><span>B</span></li>
          <li>({{ bIndexStr }})</li>
        </ul>
      </div>
      <div class="cmt_doctor">
        <strong>Comment</strong> <br><br>
        <p v-html="sample.comment"></p>
      </div>
      <div class="tip_box">
        <div>
				<span>
				장 미생물의 분포는 질병과 생활 전반의 다양한 인자가 결합되어 나타나는 건강 Balance의 원인이자 결과입니다.
				다양한 원인에 의하여 상호작용하여 나타납니다<br>
				AiB는 다양한 인자를 고려하여 생활전반의 '균형' 을 회복하는데 도움을 드리고자 합니다.<br><br>

				A : 우수(&gt;8.5), B : 양호(6.5~8.5), C : 보통(4.5~6.5), D : 주의(3~3.5), F : 경고(&lt;3)
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span></span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <section class="full">
      <table class="surveytb surveytb_1 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="">
          <col width="15%">
          <col width="11%">
          <col width="11%">
          <col width="15%">
          <col width="11%">
          <col width="11%">
          <col width="11%">
        </colgroup>
        <tr class="cate">
          <td colspan="8">고객정보답변</td>
        </tr>
        <tr class="title">
          <td>Sample ID</td>
          <td>작성일</td>
          <td>이름</td>
          <td>나이</td>
          <td>생년월일</td>
          <td>신장</td>
          <td>체중</td>
          <td>BMI</td>
        </tr>
        <tr>
          <td>{{ sample.code }}</td>
          <td>{{ moment(new Date()).format('YYYY.MM.DD') }}</td>
          <td>{{ survey.name }}</td>
          <td>{{ survey.age }}</td>
          <td>{{ survey.birthday_year + '.' + survey.birthday_month + '.' + survey.birthday_day }}</td>
          <td>{{ survey.height }}cm</td>
          <td>{{ survey.weight }}kg</td>
          <td>{{ survey.bmi }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <tr class="cate">
          <td colspan="6">현재 함께 거주하는 가족 정보</td>
        </tr>
        <tr class="title">
          <td style="width: 16.6%">부모</td>
          <td style="width: 16.6%">배우자</td>
          <td style="width: 16.6%">형제</td>
          <td style="width: 16.6%">자녀</td>
          <td style="width: 16.6%">애완동물</td>
          <td style="width: 16.6%">총</td>
        </tr>
        <tr>
          <td>{{ survey.parent_num }}</td>
          <td>{{ survey.spouse_num }}</td>
          <td>{{ survey.brother_num }}</td>
          <td>{{ survey.children_num }}</td>
          <td>{{ survey.pet_num }}</td>
          <td>{{ survey.family_num }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <colgroup>
          <col width="">
        </colgroup>
        <tr class="cate">
          <td>식사답변 (내가 즐겨먹는 음식)</td>
        </tr>
        <tr>
          <td class="food">{{ survey.like_adultfood }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tr class="cate">
          <td colspan="6">식성</td>
        </tr>
        <tr class="title">
          <td>뭐든 잘먹는 편이다</td>
          <td>달게 먹는편이다</td>
          <td>짜게먹는편이다</td>
          <td>편식하는 경향이 있다</td>
          <td>식탐이 있는편이다</td>
          <td>야식을 자주 먹는다</td>
        </tr>
        <tr>
          <td>{{ survey.taste1 }}</td>
          <td>{{ survey.taste2 }}</td>
          <td>{{ survey.taste3 }}</td>
          <td>{{ survey.taste4 }}</td>
          <td>{{ survey.taste5 }}</td>
          <td>{{ survey.taste6 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="">
        </colgroup>
        <tr class="cate">
          <td colspan="5">선호음식</td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood1 === '고기'"><em>고기</em> vs 채소 vs 생선</td>
          <td v-else-if="survey.likefood1 === '채소'">고기 vs <em>채소</em> vs 생선</td>
          <td v-else-if="survey.likefood1 === '생선'">고기 vs 채소 vs <em>생선</em></td>
          <td v-if="survey.likefood3 === '국물만'"><em>국물만</em> vs 건더기만 vs 둘다</td>
          <td v-else-if="survey.likefood3 === '건더기만'">국물만 vs <em>건더기만</em> vs 둘다</td>
          <td v-else-if="survey.likefood3 === '둘다'">국물만 vs 건더기만 vs <em>둘다</em></td>
          <td v-if="survey.likefood5 === '기름진 음식'"><em>기름진 음식</em> vs 담백한 음식</td>
          <td v-else-if="survey.likefood5 === '담백한 음식'">기름진 음식 vs <em>담백한 음식</em></td>
          <td v-if="survey.likefood7 === '김치'"><em>김치</em> vs 소시지</td>
          <td v-else-if="survey.likefood7 === '소시지'">김치 vs <em>소시지</em></td>
          <td v-if="survey.likefood9 === '파스타'"><em>파스타</em> vs 돈까스</td>
          <td v-else-if="survey.likefood9 === '돈까스'">파스타 vs <em>돈까스</em></td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood11 === '케이크'"><em>케이크</em> vs 샌드위치</td>
          <td v-else-if="survey.likefood11 === '샌드위치'">케이크 vs <em>샌드위치</em></td>
          <td v-if="survey.likefood13 === '치즈'"><em>치즈</em> vs 햄</td>
          <td v-else-if="survey.likefood13 === '햄'">치즈 vs <em>햄</em></td>
          <td v-if="survey.likefood15 === '양송이스프'"><em>양송이스프</em> vs 치킨스프</td>
          <td v-else-if="survey.likefood15 === '치킨스프'">양송이스프 vs <em>치킨스프</em></td>
          <td v-if="survey.likefood17 === '냉커피'"><em>냉커피</em> vs 이온음료</td>
          <td v-else-if="survey.likefood17 === '이온음료'">냉커피 vs <em>이온음료</em></td>
          <td v-if="survey.likefood19 === '쌀국수'"><em>쌀국수</em> vs 순두부</td>
          <td v-else-if="survey.likefood19 === '순두부'">쌀국수 vs <em>순두부</em></td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood2 === '구운요리'"><em>구운요리</em> vs 국물요리</td>
          <td v-else-if="survey.likefood2 === '국물요리'">구운요리 vs <em>국물요리</em></td>
          <td v-if="survey.likefood4 === '떡볶이'"><em>떡볶이</em> vs 과일 vs 햄버거</td>
          <td v-else-if="survey.likefood4 === '과일'">떡볶이 vs <em>과일</em> vs 햄버거</td>
          <td v-else-if="survey.likefood4 === '햄버거'">떡볶이 vs 과일 vs <em>햄버거</em></td>
          <td v-if="survey.likefood6 === '콜라'"><em>콜라</em> vs 주스</td>
          <td v-else-if="survey.likefood6 === '주스'">콜라 vs <em>주스</em></td>
          <td v-if="survey.likefood8 === '감자튀김'"><em>감자튀김</em> vs 사과</td>
          <td v-else-if="survey.likefood8 === '사과'">감자튀김 vs <em>사과</em></td>
          <td v-if="survey.likefood10 === '치킨'"><em>치킨</em> vs 백숙</td>
          <td v-else-if="survey.likefood10 === '백숙'">치킨 vs <em>백숙</em></td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood12 === '김밥'"><em>김밥</em> vs 라면</td>
          <td v-else-if="survey.likefood12 === '라면'">김밥 vs <em>라면</em></td>
          <td v-if="survey.likefood14 === '고구마'"><em>고구마</em> vs 바나나</td>
          <td v-else-if="survey.likefood14 === '바나나'">고구마 vs <em>바나나</em></td>
          <td v-if="survey.likefood16 === '주스'"><em>주스</em> vs 요구르트</td>
          <td v-else-if="survey.likefood16 === '요구르트'">주스 vs <em>요구르트</em></td>
          <td v-if="survey.likefood18 === '팬케이크'"><em>팬케이크</em> vs 시리얼</td>
          <td v-else-if="survey.likefood18 === '시리얼'">팬케이크 vs <em>시리얼</em></td>
          <td v-if="survey.likefood20 === '아메리카노'"><em>아메리카노</em> vs 마끼야또</td>
          <td v-else-if="survey.likefood20 === '마끼야또'">아메리카노 vs <em>마끼야또</em></td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="">
        </colgroup>
        <tr class="cate">
          <td colspan="3">건강 답변 “수면”</td>
          <td colspan="2">건강 답변 “운동”</td>
        </tr>
        <tr class="title">
          <td>자다가 자주 깬다</td>
          <td>쉽게 잠들기 어렵다</td>
          <td>하루 총 수면</td>
          <td>주3회이상 운동을 한다</td>
          <td>운동을 좋아하는 편이다</td>
        </tr>
        <tr>
          <td>{{ survey.sleep1 }}</td>
          <td>{{ survey.sleep2 }}</td>
          <td>{{ survey.sleep3 }}시간</td>
          <td>{{ survey.exercise1 }}</td>
          <td>{{ survey.exercise2 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="25%">
          <col width="25%">
          <col width="25%">
          <col width="">
        </colgroup>
        <tr class="cate">
          <td colspan="4">건강 답변 “음주흡연”</td>
        </tr>
        <tr class="title">
          <td>술을 좋아하는편이다</td>
          <td>독한술 보다는 맥주 · 와인이 좋다</td>
          <td>주 2회이상 마신다</td>
          <td>담배를 1주에 1갑이상 핀다</td>
        </tr>
        <tr>
          <td>{{ survey.drinksmoke1 }}</td>
          <td>{{ survey.drinksmoke2 }}</td>
          <td>{{ survey.drinksmoke3 }}</td>
          <td>{{ survey.drinksmoke4 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="25%">
          <col width="25%">
          <col width="25%">
          <col width="">
        </colgroup>
        <tr class="cate">
          <td colspan="4">건강 답변 “감성”</td>
        </tr>
        <tr class="title">
          <td>조용하고 침착하다</td>
          <td>화가 많은 편이다</td>
          <td>잘 웃는다</td>
          <td>스트레스가 많은편이다</td>
        </tr>
        <tr>
          <td>{{ survey.sens1 }}점</td>
          <td>{{ survey.sens2 }}점</td>
          <td>{{ survey.sens3 }}점</td>
          <td>{{ survey.sens4 }}점</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="33%">
          <col width="33%">
          <col width="">
        </colgroup>
        <tr class="cate">
          <td>최근 2주이내 병원에 간적이 있다면</td>
          <td>가장 빈번하게 병원을 찾는 이유는?</td>
          <td>만성질환이 있나요?</td>
        </tr>
        <tr>
          <td>{{ survey.disease_weekly }}</td>
          <td>{{ survey.disease_frequently }}</td>
          <td>{{ survey.disease_chronic }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tr class="cate">
          <td colspan="6">내가 생각하는 내 건강</td>
        </tr>
        <tr class="title">
          <td>근력</td>
          <td>순환기</td>
          <td>간</td>
          <td>신장/비뇨기</td>
          <td>소화기</td>
          <td>피부</td>
        </tr>
        <tr>
          <td>{{ survey.health1 }}</td>
          <td>{{ survey.health2 }}</td>
          <td>{{ survey.health3 }}</td>
          <td>{{ survey.health4 }}</td>
          <td>{{ survey.health5 }}</td>
          <td>{{ survey.health6 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody></tbody>
        <colgroup>
          <col width="">
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
        </colgroup>
        <tbody>
        <tr class="cate">
          <td colspan="5">건강식품이나 상시 복용중인 약품</td>
        </tr>
        <tr class="title">
          <td>프로바이오틱스</td>
          <td>홍삼</td>
          <td>한약</td>
          <td>비타민</td>
          <td>기타</td>
        </tr>
        <tr>
          <td>{{ survey.healthfood1 || '-' }}</td>
          <td>{{ survey.healthfood2 || '-' }}</td>
          <td>{{ survey.healthfood3 || '-' }}</td>
          <td>{{ survey.healthfood4 || '-' }}</td>
          <td>{{ survey.healthfood5 || '-' }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody></tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tbody>
        <tr class="cate">
          <td colspan="6">가족력</td>
        </tr>
        <tr class="title">
          <td>혈관 심장질환<span>(고혈압, 고지혈증, 심근경색 등)</span></td>
          <td>위장관 질환<span>(위염, 궤양, 과민성대장염 등)</span></td>
          <td>호흡기 질환<span>(폐렴, 천식, 만성폐질환 등)</span></td>
          <td>신장질환<span>(신부전, 신증후군 등)</span></td>
          <td>알레르기 질환<span>(신부전, 신증후군 등)</span></td>
          <td>내분비<span>(비만, 당뇨, 갑상선 등)</span></td>
        </tr>
        <tr>
          <td>{{ survey.hypertension || '-' }}</td>
          <td>{{ survey.heart_disease || '-' }}</td>
          <td>{{ survey.stroke || '-' }}</td>
          <td>{{ survey.breast_cancer || '-' }}</td>
          <td>{{ survey.lung_cancer || '-' }}</td>
          <td>{{ survey.diabetes || '-' }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2 surveytb_etc">
        <tbody></tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tbody>
        <tr class="title">
          <td>뇌질환<span>(치매, 뇌졸중, 간질 등)</span></td>
          <td>각종 암<span>(폐암, 위암, 간암 등)</span></td>
          <td>류마치스, 면역질환</td>
          <td>감염질환</td>
          <td>기타</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ survey.hyperlipidemia || '-' }}</td>
          <td>{{ survey.osteoporosis || '-' }}</td>
          <td>{{ survey.colorectal_cancer || '-' }}</td>
          <td>{{ survey.thyroid_cancer || '-' }}</td>
          <td>{{ survey.stomach_cancer || '-' }}</td>
          <td>-</td>
        </tr>
        </tbody>
      </table>
    </section>
    <!-- 페이지끝 -->


    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_obesity.png" alt="balance1 성장"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="survey_box">
        <ul class="three">
          <li>
            <span><em>· Sample ID</em>{{ sample.code }}</span>
            <span><em>· 작성일</em>{{ moment(new Date()).format('YYYY년 M월 D일') }}</span>
            <span><em>· 이름</em>{{ survey.name }}</span>

          </li>
          <li>
            <span><em>· 나이</em>{{ survey.age }}</span>
            <span><em>· 생년월일</em>{{
                survey.birthday_year + '년 ' + survey.birthday_month + '월 ' + survey.birthday_day + '일'
              }}</span>
            <span><em>· 신장</em>{{ survey.height }}cm</span>

          </li>
          <li>
            <span><em>· 체중</em>{{ survey.weight }}kg</span>
            <span><em>· BMI</em>{{ survey.bmi }}</span>
            <span><em>· 가족정보</em>총 {{
                survey.parent_num + survey.brother_num
              }}명 (부모{{ survey.parent_num }},형제{{ survey.brother_num }})</span>
          </li>
        </ul>
      </div>
      <div class="data_box">
        <ul>
          <li style="height: 476px;">
            <h3>키성장</h3>
            <span class="graph_area_grow"> <v-chart style="left:-38px;" :options="heightChartData"
                                                    :init-options="{ width: 500, height : 423}" theme='shine'/></span>
            <span class="data_txt mint">36개월 여아의 범위는 80~100cm 로 현재 93cm로 연령대의 하위에서 점차 평균치로 상승중입니다.</span>
          </li>
          <li>
            <h3>체중의 변화</h3>
            <span class="graph_area_grow"> <v-chart style="left:-38px;" :options="weightChartData"
                                                    :init-options="{ width: 510, height : 423}" theme='shine'/></span>
            <span class="data_txt mint">36개월 여아의 범위는 80~100cm 로 현재 93cm로 연령대의 하위에서 점차 평균치로 상승중입니다.</span>
          </li>
        </ul>
      </div>
      <div class="tip_box">
        <div>
				<span>
				비만을 만병의 근원이라 이야기 하기도 하지만 비만에도 다양한 종류가 있습니다. 특히 운동을 하는 비만과 운동을 하지 않는 비만은 장건강의 관점에서는 매우 다르게 판단할 수 있습니다.<br><br>

				비만한 사람이 운동량이 부족할 경우 다양성이 낮아지고, 음주나 기타 나쁜 습관이 더해지면 유해균이 증가합니다. 장에 유해균이 증가된다고 해도 단기적으로는 아무런 증상이나 불편함을 느끼지 못할 수도 있습니다. 하지만 장내 유해균은 아주 조금씩 인간 숙주를 더 큰 건강의 위험으로 몰아갈 수도 있습니다.<br><br>

				하지만 체중을 조절하기 위해 굶는 것은 장의 입장에서 오히려 더 가혹합니다. 차라리 먹고 운동을 하는 게 훨씬 좋습니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>5</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->


    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_2.png" alt="식생활"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자
        마지막입니다.
      </div>
      <div class="survey_box">
        <ul class="three">
          <li>
            <span><em>· Sample ID</em>{{ sample.code }}</span>
            <span><em>· 작성일</em>{{ moment(new Date()).format('YYYY년 M월 D일') }}</span>
            <span><em>· 이름</em>{{ survey.name }}</span>
          </li>
          <li>
            <span><em>· 나이</em>{{ survey.age }}</span>
            <span><em>· 생년월일</em>{{
                survey.birthday_year + '년 ' + survey.birthday_month + '월 ' + survey.birthday_day + '일'
              }}</span>
            <span><em>· 신장</em>{{ survey.height }}cm</span>
          </li>
          <li>
            <span><em>· 체중</em>{{ survey.weight }}kg</span>
            <span><em>· BMI</em>{{ survey.bmi }}</span>
            <span><em>· 가족정보</em>총 {{
                survey.parent_num + survey.brother_num
              }}명 (부모{{ survey.parent_num }},형제{{ survey.brother_num }})</span>
          </li>
        </ul>
      </div>
      <div class="babylikefoodtbwrap">
        <h3>내가 고른 내 식탁</h3>
        <food-table></food-table>
      </div>

    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>6</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_2.png" alt="식생활"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자
        마지막입니다.
      </div>
      <div class="data_box data_box_food">
        <ul class="food_data_graph">
          <li>
					<span class="title">
						식이취향 (식재료)
						<span><em>고객님의 취향</em><em>적정수준</em></span>
					</span>
            <div class="graph_area_living">
              <v-chart :options="tastesList.type1" :initOptions="{
            width: 500, height : 300 }" theme='shine'/>
            </div>
          </li>
          <li>
					<span class="title">
						식이취향 (조리법)
						<span><em>고객님의 취향</em><em>적정수준</em></span>
					</span>
            <div class="graph_area_living">
              <v-chart :options="tastesList.type2" :initOptions="{
            width: 500, height : 300 }" theme='shine'/>
            </div>
          </li>
        </ul>
        <div class="txt_area">
          같은 식탁에 앉아도 선택은 결국 개인의 취향입니다.<br>
          고기만 골라 먹는 아들, 다이어트 한다면서 면만 좋아하는 딸, 뭐든 다 잘 먹지만 너무 많이 먹는 아빠,
          결국 같은 식탁에서 식구들은 조금씩 다르게 식사를 하게 됩니다.<br><br>

          설문으로 알아본 당신의 취향을 식재료별로 혹은 조리방법으로 구분해 알아봅시다.<br><br>

          육류, 탄수화물, 채소, 발효식품 등의 취향과 달고 짜게 혹은 기름지게 먹는 습관까지 알아봅니다.

          어떤 영역도 절대적으로 나쁘고 좋은 건 없습니다. 얼마나 고루 먹는 습관을 가지고 있는지 알아보는 테스트 결과입니다.<br><br>

          실제 먹는 음식과 다를 수도 있지만 내 취향을 알고 적절하게 조절할 수 있다면 더욱 건강한 장을 가질 수 있습니다.
        </div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				비만이 걱정스러운 또 다른 이유는 비만이 아주 다양한 성인병의 원인이 되고 특히 서구화된 식단이 도입되면서 동양인의 당뇨병 유병률이 급격하게 증가되기 때문입니다.<br><br>

				인류는 아주 오랫동안 다른 지역에서 다른 문화와 자연에 적응하며 살아왔습니다. 수만 년의 시간 동안 우리 몸은 다른 식습관에 적응이 되어 왔습니다. 국가별로 Microbiome에 대한 연구를 다르게 하는 이유입니다.<br><br>

				오랜 시간 동안 길들여진 우리의 몸이 최근 수십년간 급격히 서구화된 식단에 잘 적응하지 못하는 경우가 많습니다. 미국 이민자 사회에서 아이의 당뇨병 유병률이 백인에 비해 크게 차이가 나는 연구결과가 있습니다. 같은 음식을 먹어도 인종마다 다른 결과를 보이고 있습니다.<br><br>

				또 정반대로 부모의 채식주의 때문에 아이에게 채식만을 강요하는 것도 바람직하지 않습니다. 성장기의 어린이는 다양한 동식물성 단백질이 필요합니다. 음식을 균형 있게 섭취하는 것이 무엇보다 중요합니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>7</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_3.png" alt="운동"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자
        마지막입니다.
      </div>
      <div class="survey_box">
        <ul class="two">
          <li class="wide">
            <span><em>· 주3회이상 땀흘리는 운동을 한다</em>{{ survey.exercise1 }}</span>
            <span><em>· 운동을 좋아하는 편이다</em>{{ survey.exercise2 }}</span>
          </li>
        </ul>
      </div>
      <div class="data_box">
        <div class="txt_area margintop0">
          불로장생약을 찾는 인간의 노력은 아주 오래 전 진시황때부터 있어 왔지만 아직은 아무도 찾지 못했습니다.<br><br>

          궁중음식은 건강식의 대표적인 사례가 됩니다. 그럼에도 불구하고 조선의 왕들은 이런저런 이유로 평균 수명이 45세밖에 되지 않습니다. 좋은 음식만으로는 건강한 노후를 보장할 수 없습니다.<br>
          좋은 약도 마찬가지입니다. 다양한 노력 중에 가장 중요한 노력이 운동과 음식입니다.<br><br>

          미국 콜로라도대 연구팀의 연구결과에서 운동을 하면 장내 미생물이 증가되고 정신건강과 몸의 대사 활동에 도움이 된다는 연구를 발표한 바 있습니다. 운동은 근육만 키우는 것이 아니라 장내 유익한 아군까지 같이 키우는 셈입니다.<br><br>

          사람마다 필요한 운동의 요소는 조금씩 다릅니다. 근력 운동이 필요한 사람이 있고 유산소 운동이 필요한 사람이 있습니다. 저마다 필요한 운동을 조금씩이라도 실천한다면 맛있는 음식을 더 많이 먹을 수 있는 기회가 생깁니다.
        </div>
        <div class="img_exercise" style="margin-bottom: 50px"></div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				운동량이 부족한 경우 잉여의 영양분은 때로 독이 되기도 합니다.<br>
				특히 성장이 끝난 성인의 경우에는 더욱 그러합니다.<br>

				비만 역시 성인병의 적이기 때문에 굶는 다이어트를 하기도 하지만 이 역시 좋지 않습니다.
				운동은 단순히 근육을 키우거나 열량을 소비하여 비만을 방지할 뿐 아니라 유해균을 억제하고 유익균을 증가시키는 역할도 하고 있습니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>8</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_4_adult.png" alt="수면"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자
        마지막입니다.
      </div>
      <div class="survey_box">
        <ul class="three family_history">
          <li class="w1_title">
					<span>
						<em>· 자다가 자주 깬다</em>{{ survey.sleep1 }}
					</span>
            <span>
						<em>· 쉽게 잠들기 어렵다</em>{{ survey.sleep2 }}
					</span>
            <span>
						<em>· 하루 총 수면</em>{{ survey.sleep3 }}시간
					</span>
          </li>
          <li class="w2_title">
            <span><em>· 술을 좋아하는편이다</em>{{ survey.drinksmoke1 }}</span>
            <span><em>· 독한술 보다는 맥주 · 와인이 좋다</em>{{ survey.drinksmoke2 }}</span>
            <span><em>· 주 2회이상 마신다</em>{{ survey.drinksmoke3 }}</span>
            <span><em>· 담배를 1주에 1갑이상 핀다</em>{{ survey.drinksmoke4 }}</span>
          </li>
          <li class="w3_title">
					<span>
						<em>· 조용하고 침착하다</em>{{ survey.sens1 }}점
					</span>
            <span>
						<em>· 화가 많은 편이다</em>{{ survey.sens2 }}점
					</span>
            <span>
						<em>· 잘 웃는다</em>{{ survey.sens3 }}점
					</span>
            <span>
						<em>· 스트레스가 많은편이다</em>{{ survey.sens4 }}점
					</span>
          </li>
        </ul>
      </div>
      <div class="data_box data_margint0">
        <div class="txt_area">
          <h3>수면, 음주 흡연, 감성</h3>
          생활 습관은 육체적으로 혹은 정신적으로 건강에 많은 영향을 미치고 있습니다. 운동뿐 아니라 음주와 수면 그리고 스트레스까지 다양한 요인들이 건강과 장에 영향을 줍니다.<br><br>
          잦은 음주는 장을 조금씩 지치게 만들고 있습니다. 독주는 장벽에 영향을 주고 장벽에서 면역을 지켜주는 유익균을 공격하여 장을 조금씩 병들게 합니다. 그리고 수면의 질과 양은 몸을 회복하는데 중요한 역할을 하기 때문에 매우 중요합니다.<br><br>
          스트레스는 특히 위와 장에 영향을 많이 주는 요인입니다. 스트레스를 없앨수 없다면 이완시킬 수 있는 다른 방법을 찾아야 합니다.

        </div>
      </div>

    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>10</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->


    <!-- 페이지시작 -->
    <section class="cover">
      <div><img src="img/report_information3.jpg"></div>
    </section>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_5_adult.png" alt="가족력"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자
        마지막입니다.
      </div>
      <table class="surveytb surveytb_2">
        <tbody>
        <colgroup>
          <col width="33%">
          <col width="33%">
          <col width="">
        </colgroup>
        <tr class="cate">
          <td>최근 2주이내 병원에 간적이 있다면</td>
          <td>가장 빈번하게 병원을 찾는 이유는?</td>
          <td>만성질환이 있나요?</td>
        </tr>
        <tr>
          <td>{{ survey.disease_weekly }}</td>
          <td>{{ survey.disease_frequently }}</td>
          <td>{{ survey.disease_chronic }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tr class="cate">
          <td colspan="6">내가 생각하는 내 건강</td>
        </tr>
        <tr class="title">
          <td>근력</td>
          <td>순환기</td>
          <td>간</td>
          <td>신장/비뇨기</td>
          <td>소화기</td>
          <td>피부</td>
        </tr>
        <tr>
          <td>{{ survey.health1 }}</td>
          <td>{{ survey.health2 }}</td>
          <td>{{ survey.health3 }}</td>
          <td>{{ survey.health4 }}</td>
          <td>{{ survey.health5 }}</td>
          <td>{{ survey.health6 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody></tbody>
        <colgroup>
          <col width="">
          <col width="20%">
          <col width="20%">
          <col width="20%">
          <col width="20%">
        </colgroup>
        <tbody>
        <tr class="cate">
          <td colspan="5">건강식품이나 상시 복용중인 약품</td>
        </tr>
        <tr class="title">
          <td>프로바이오틱스</td>
          <td>홍삼</td>
          <td>한약</td>
          <td>비타민</td>
          <td>기타</td>
        </tr>
        <tr>
          <td>{{ survey.healthfood1 || '-' }}</td>
          <td>{{ survey.healthfood2 || '-' }}</td>
          <td>{{ survey.healthfood3 || '-' }}</td>
          <td>{{ survey.healthfood4 || '-' }}</td>
          <td>{{ survey.healthfood5 || '-' }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody></tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tbody>
        <tr class="cate">
          <td colspan="6">가족력</td>
        </tr>
        <tr class="title">
          <td>혈관 심장질환<span>(고혈압, 고지혈증, 심근경색 등)</span></td>
          <td>위장관 질환<span>(위염, 궤양, 과민성대장염 등)</span></td>
          <td>호흡기 질환<span>(폐렴, 천식, 만성폐질환 등)</span></td>
          <td>신장질환<span>(신부전, 신증후군 등)</span></td>
          <td>알레르기 질환<span>(신부전, 신증후군 등)</span></td>
          <td>내분비<span>(비만, 당뇨, 갑상선 등)</span></td>
        </tr>
        <tr>
          <td>{{ survey.hypertension || '-' }}</td>
          <td>{{ survey.heart_disease || '-' }}</td>
          <td>{{ survey.stroke || '-' }}</td>
          <td>{{ survey.breast_cancer || '-' }}</td>
          <td>{{ survey.lung_cancer || '-' }}</td>
          <td>{{ survey.diabetes || '-' }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody></tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tbody>
        <tr class="title">
          <td>뇌질환<span>(치매, 뇌졸중, 간질 등)</span></td>
          <td>각종 암<span>(폐암, 위암, 간암 등)</span></td>
          <td>류마치스, 면역질환</td>
          <td>감염질환</td>
          <td>기타</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ survey.hyperlipidemia || '-' }}</td>
          <td>{{ survey.osteoporosis || '-' }}</td>
          <td>{{ survey.colorectal_cancer || '-' }}</td>
          <td>{{ survey.thyroid_cancer || '-' }}</td>
          <td>{{ survey.stomach_cancer || '-' }}</td>
          <td>-</td>
        </tr>
        </tbody>
      </table>
      <div class="data_box data_margint0">
        <div class="txt_area">
          <h3>자가건강, 가족력</h3>
          가족력은 후천적인 유전 혹은 사회적 유전이라 부를 수 있습니다.<br>
          선천적인 유전병인 다운증후군이나 색맹, 혈우병에 비해 가족력은 고혈압, 당뇨, 고지혈증, 심장병, 탈모, 암 등이 대표적입니다.<br><br>
          심장병은 가족중 환자가 있을 때 발병률이 2배 이상이며 당뇨병 역시 부모가 당뇨병이 있을경우 30~40% 더 발병률이 높습니다. 암의 경우에도 <br>부모가 암일경우 자식의 발병률이 2~5배 높은 것으로 국제 암학회가 밝히고 있습니다.

        </div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				가족력에서 제일 중요한 부분이 생활 습관입니다.<br><br>

				특히 같이 식생활을 하는 ‘식구’는 유사한 식이 취향을 가지게 되며 장기간 지속되면 장의 환경 즉 장 미생물의 생태계가 유사해지기도 합니다. 장 미생물의 생태계는 대장암이나 당뇨와 매우 관계가 높다고 알려져 있으며 이것이 식습관과 매우 관련이 높은것으로 판단하고 있습니다.<br><br>

				좋은 식습관을 가지도록 하는 밥상머리 교육이 그만큼 중요하다는 의미입니다. 하지만 부모의 그릇된 지식이나 선입견 때문에 잘못된 식습관을 갖게 되는 경우도 있습니다.<br><br>
				장 미생물 검사는 그런 측면에서도 매우 중요합니다. 그동안 내 생활 습관이 몸에 올바로 영향을 미쳤는지 확인할 수 있습니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>13</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_summary.png" alt="balance summary"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="balance_summary_main">
        <div>
          <v-chart :options="balance.totalBalanceGaugeData" ref="total" theme='shine'
                   :initOptions="{ width: 700, height : 600}"></v-chart>
        </div>
        <strong>장내 미생물 기능별 분포 수준</strong>
        <!-- class grade1~5까지 제어  -->
        <span :class="balance.totalBalanceGaugeData.levelGroup.cssClass">
      </span>
      </div>
      <div class="tip_box">
        <div>
				<span>
				균형 지수의 Level은 Level-2 에서 Level2 까지 5단계로 구분됩니다. 해당 기능에 관련이 있는 수십종의 균종이 균형을 이루고 있는지 알아보는 그래프입니다. 해당 기능과 관련이 있는 유익균과 유해균중 유해균의 비율이 높은 경우 Level이 낮아집니다. 반대로 유익균의 비율이 높으면 Level이 높아집니다.<br><br>

				이 Level은 질병을 의미하진 않습니다. 사람에 따라 면역력이 약해질 때, Level이 낮은 경우에는 유해성을 가진 균종들이 유익균보다 더 많아지고 있으니 주의해야 한다는 의미입니다.<br><br>

				장 미생물은 다양한 질병과 관련이 있다고 알려져 있지만 질병과 모두 직접적으로 연관되지 않는 경우도 많습니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>14</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_summary.png" alt="balance summary"><span class="miso"></span></h2>
    </div>
    <section class="margin0">
      <ul class="balance_summary_detail">
        <li>
          <ul>
            <li class="img_gauge">
              <v-chart :options="balance.gutBalanceGaugeData" ref="total" theme='shine'
                       :initOptions="balanceGauge.size"></v-chart>
            </li>
            <li>
              <span><strong>장건강</strong><em
                  :class="balance.gutBalanceGaugeData.levelGroup.cssClass"><em>level</em><span>2</span></em></span>
              <span>장건강과 관련된 53종의 장내 미생물 분석 결과입니다. </span>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li class="img_gauge">
              <v-chart :options="balance.immunityBalanceGaugeData" ref="total" theme='shine'
                       :initOptions="balanceGauge.size"></v-chart>
            </li>
            <li>
              <span><strong>장건강</strong><em :class="balance.immunityBalanceGaugeData.levelGroup.cssClass"><em>level</em><span>1</span></em></span>
              <span>장건강과 관련된 53종의 장내 미생물 분석 결과입니다. </span>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li class="img_gauge">
              <v-chart :options="balance.ageBalanceGaugeData" ref="total" theme='shine'
                       :initOptions="balanceGauge.size"></v-chart>
            </li>
            <li>
              <span><strong>장건강</strong><em
                  :class="balance.ageBalanceGaugeData.levelGroup.cssClass"><em>level</em><span>0</span></em></span>
              <span>장/면역 밸러스 지수는 유익균과 유해균의 균형을 나타내고 있습니다.<br>어떤 유해균이 특별히 많은지 살펴볼 수 있습니다.</span>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>15</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_aibchart.png" alt="aibchart"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="data_box data_box_graph">
        <h3>임상적 분류</h3>
        <div class="graph_area_diversity">
          <v-chart :options="aibChart1" ref="polar" theme='shine' :initOptions="{width: 1038}"/>
        </div>
        <h3>생물학적 분류</h3>
        <div class="graph_area_diversity">
          <v-chart :options="aibChart2" theme='shine' :initOptions="{width: 1038}"/>
        </div>

        <div class="txt_area">
          AiB Chart는 AiBiotics 사의 독창적인 Microbiome 지도입니다.<br>
          수 백여 종의 장내 미생물을 보편적으로 많이 존재하는 순서로 나열한 다음 나의 수준을 비교하는 그래프입니다. 보편적으로 존재하는 장 미생물이 결핍되거나, 혹은 특정한 균이 특이하게 많이 존재하는 경우를 살펴보는 방법입니다.
        </div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				임상적 분류 : 많은 연구를 통해 유해성이 보고되거나, 유익성이 보고 된 경우 유해균과 유익균으로 구분하였습니다. 하지만 유해균으로 분류된 붉은색 선이 반드시 병을 유발한다는 증거는 없습니다. 하지만 그럴 확률은 높은게 사실입니다. 또한 결핍이 많은 경우에도 역시 임상적으로 여러가지 증상을 보이는 경우가 많습니다.<br><br>

				생물학적 분류 : 장 미생물의 여러가지 분류 기준 중에서 Phylum(문) 기준으로 분류한 그래프입니다. 생물학적인 구분기준으로 나누었지만 각 균들의 특성들이 있어 장 건강을 일부 대면하기도 합니다. 박테로이데테스(Bacteroidetes)와 퍼미큐티스(Firmicutes)가 가장 보편적으로 많은 균입니다. 이외에 프로테오박테리아나 푸조박테리아에는 병원성 균이 많이 포함되어 있습니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>16</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_index.png" alt="AiBINDEX"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="data_box index">
        <ul class="food_data_graph aib_index">
          <li>
            <div id="now"></div>
            <div class="index_old">
              <span class="title">한국인 나이별 다양성 분포</span>
              <div>
                <v-chart :options="byAgeDiversity" :init-options="{width:500, height:300}"/>
              </div>
            </div>
          </li>
          <li>
            <span class="title">AiB index</span>
            <div class="graph_area_living_freq">
              <v-chart :options="freqDistributionListChart" :init-options="{width:520, height:344}" theme='shine'/>
            </div>
          </li>
        </ul>
        <ul class="index_result">
          <li>
            <span class="standard">총 검사 대상 균종 <em>{{aibTotal.avg||0}}</em>개 중</span>
            <span>확인된 균종 <em>{{aibTotal.user||0}}</em>종</span>
          </li>
        </ul>
        <div class="txt_area">
          다양성 분포는 AiB index를 이용한 히스토그램으로 표현하고 있습니다.
          전체 분포 중에서 당신의 다양성의 위치를 확인하세요.<br><br>

          장 미생물의 분석에서 다양성을 매우 중요한 Index로 여기고 있지만 그 정확한 기준이 없습니다.
          그저 많기만 하다고 좋은 것만은 아닙니다.<br>
          AiB index는 '과잉'을 제외한 분포를 Log 함수를 이용하여 다양성 지수를 산출하고 있습니다.
        </div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				인체에 존재하는 미생물은 대략 40~50조 정도로 알려져 있습니다. 분석을 통해 우리가 알 수 있는 균종은 불과 수백 종에 불과합니다.
				20년 동안 연구 되어온 균중들의 분포를 알아보면 얼마나 다양한 균종들이 내 몸에서 사는지 확인할 수 있습니다.<br><br>

				성장하면서 다양성은 지속 증가하는 것이 일반적이지만 질병이나, 투약 혹은 다른 원인으로 인하여 미생물의 생태계는 지속 변화합니다.<br><br>

				AiB index는 다양성을 표현하기 위한 방법으로 발견되는 종의 수와 양을 결합하여 Index(지수)로 표현하고 있습니다.<br><br>

				이 점수는 그 자체로 의미가 있다기 보다는 상대적인 비교를 위한 수단으로 생각할 수 있습니다. 지난번 아플 때 200이었던 <br>다양성 지수가 이번에 300이 되었다면 다양성이 50% 상승되었다고 이해할 수 있습니다.
				</span>
        </div>
      </div>
      <div class="index_bobg"></div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>17</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_phylum.png" alt="phylum분석"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="txt_top">
        Microbiome 의 생태학적인 분류로 20여개의 Phylum(문) Level의 분류법이 있습니다.<br>
        Bacteroidetes와 Firmicutes가 가장 보편적인 균들이며 유익균, 유해균, 공생균이 섞여있습니다.<br>
        반면에 Fusobacteria와 ProteoBacteria는 유해성이 큰 균들이 다수 포함되어 있어 일반적으로 유해균으로 분류합니다.<br>
        Major 균종의 비율과 소수 균종이 특이하게 증가될 때 자세하게 확인해 보아야 합니다.
      </div>
      <div class="analysis_box">
        <ul>
          <li>
            <v-chart :options="phylum.avgPieData" :init-options="{width: 519, height: 473}" theme='shine'/>
          </li>
          <li>
            <v-chart :options="phylum.userPieData" :init-options="{width: 519, height: 473}" theme='shine'/>
          </li>
        </ul>
      </div>
      <dl class="analysis_list">
        <dt>
          <strong>Actinobacteria</strong>
          <span>
          <v-chart :options="reportPhylum.Actinobacteria" :init-options="{width: 290, height: 180}"/>
        </span>
        </dt>
        <dd>
          악티노박테리아(Actinobacteria)의 대표 선수는 비피더스라고 불리는 비피도박테리아(Bifidobacteria) <br> 입니다.<br>
          비피더스는 대표적인 유산균으로서 장 건강에 도움이 되는 균으로 규명되어  먹으면 건강에 도움을 주는 균인 ‘프로바이오틱스’로 인정받고 있습니다. 비피더스외에도 많은 종류의 균들이 있으며 공생균, 유해균도 다수 포함되어 있습니다.
        </dd>
      </dl>
      <dl class="analysis_list">
        <dt>
          <strong>Firmicutes</strong>
          <span>
          <v-chart :options="reportPhylum.Firmicutes" :init-options="{width: 290, height: 180}"/>
        </span>
        </dt>
        <dd>
          퍼미큐티스( Firmicutes)는 마이크로바이옴 분석시 가장 많은 종류가 발견되는 균종입니다.<br>
          지방대사와의 관련성 때문에 비만균이라는 오명을 쓰고 있지만 실제로는 락토바실러스를 비롯한 다양한 유산균이 포함되는 그룹입니다. 유익균과 유해균이 모두 포함되어 있습니다. 하지만 microbiome 검사에서 가장 중요한 ‘다양성’의 측면에서 기여도가 가장 큰 그룹입니다.
        </dd>
      </dl>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>18</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_phylum.png" alt="phylum분석"><span class="miso"></span></h2>
    </div>
    <section>
      <dl class="analysis_list nextPage">
        <dt>
          <strong>Bacteroidetes</strong>
          <span>
          <v-chart :options="reportPhylum.Bacteroidetes" :init-options="{width: 290, height: 180}"/>
        </span>
        </dt>
        <dd>
          박테로이데테스(Bacteroidetes)의 세부종은 퍼미큐티스(Firmicutes)보다 작지만 가장 보편적인 장 미생물의 그룹입니다.<br><br>

          세부종은 83종으로 Firmicutes보다 작지만 가장 보편적인 장 미생물의 그룹입니다.<br>
          고형식을 시작하는 아이들이 가장 먼저 발견되는 균종으로 양만으로 따지면 전체의 절반을 차지하고
          있습니다. 대부분이 공생균이지만 일부 세부 균종 중에는 유익균과 유해균이 공존합니다.
        </dd>
      </dl>
      <dl class="analysis_list">
        <dt>
          <strong>Proteobacteria</strong>
          <span>
          <v-chart :options="reportPhylum.Proteobacteria" :init-options="{width: 290, height: 180}"/>
        </span>
        </dt>
        <dd>
          프로테오박테리아(Proteobacteria)는 성인에서 대략 10% 정도 분포합니다. 대부분 공생균이지만 다른 그룹 대비 유해균이 많은 그룹입니다.<br><br>

          프로테오박테리아(Proteobacteria)와 푸조박테리아는(FusoBacteria)는 주로 병원성균을 많이 포함하고 있습니다. 수치적으로도
          전체 분포에서 푸조는 1%미만, 프로테오는 10%수준이며 적극적으로 관리해야 하는 대상입니다.<br><br>
          특히 병원성이 확인된 균들은 작을수록 좋다고 볼 수 있습니다.
        </dd>
      </dl>
      <dl class="analysis_list">
        <dt>
          <strong>Fusobacterium</strong>
          <span>
          <v-chart :options="reportPhylum.Fusobacteria" :init-options="{width: 290, height: 180}"/>
        </span>
        </dt>
        <dd>
          푸조박테리아는(FusoBacteria)는 주로 병원성 균을 많이 포함하고 있습니다.<br>
          장이 불안정한 아기들도 70~80%는 발견되지 않습니다.<br>
          발견되는 것만으로도 경고신호로 보는 것이 좋겠습니다.<br><br>
          특히 병원성이 확인된 균들은 작을수록 좋다고 볼 수 있습니다.
        </dd>
      </dl>
      <div class="tip_box">
        <div>
				<span>
				Phylum은 생물을 분류하는 기준 중에서 아주 큰 분류 기준입니다.<br>
				예를 들어 문어, 오징어는 연체동물문이며, 메뚜기, 지네등은 절지동물문이고, 인간은 금붕어, 상어, 늑대, 곰과 같은 <br>척삭동물문에 속합니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>19</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_entero.png" alt="장유형"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="txt_top">
        장 유형은 장내 세균의 분포를 기준으로 판단하는 패턴입니다.<br>
        식이패턴, 운동등의 생활습관, 약물의 복용등의 다양한 요인에 의하여 만들어지는 장의 유형을 나타냅니다
      </div>
      <div class="analysis_box">
        <ul>
          <li>
            <v-chart :options="enterotypePieChart.avg" :init-options="{width: 519, height: 473}" theme='shine'/>
          </li>
          <li>
            <v-chart :options="enterotypePieChart.user" :init-options="{width: 519, height: 473}" theme='shine'/>
          </li>
        </ul>
      </div>
      <div class="data_box">
        <div class="txt_area margintop0">
          장 유형은 2011년 연구된 논문에서 '장기적인 식습관으로 인하여 만들어지는 몇 가지 패턴'을 확인하고 이 Type을 장 유형으로 표현하고 있습니다.<br><br>


          <span>EnteroType1: 박테로이데스(Bacteroides)</span><br>
          육식을 주로 하는 유럽인의 일반적인 Type입니다. 육식과 식이 섬유가 부족한 전분을 섭취하는 경우 많이 나타납니다. 빵과 고기 혹은 밥과 고기를 주로 먹는 경우에 해당합니다. 우리나라의 어린이들에게
          흔히 나타나는 Type입니다.<br><br>

          <span>EnteroType2: 프리보텔라(Prevotella)</span><br>
          성인의 경우에는 채식을 위주로 하는 식습관을 가지는 경우 나타나고 있습니다. 극단적으로 식이 섬유만 먹는다고 해도 100%가 되지는 않습니다. 분유만 먹는 아기들의 경우에도 주로 이 Type이
          많습니다. 가끔 항생제를 복용한 후에 프리보텔라만 상대적으로 작아지는 경우도 있습니다.<br><br>

          <span>EnteroType3: 루미노코커스(Ruminococcus)</span><br>
          포도당 흡수를 도와서 비만을 유발하는 균으로 알려져 있지만 실제 한국인의 경우 잘 나타나지 않습니다.

        </div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				EnteroType은 주로 장기적인 식이 취향에 의하여 장 미생물의 우점종이 변경되는 경우 입니다.<br><br>

				하지만 가끔 항생제를 복용한 후에 프리보텔라만 상대적으로 작아지는 경우도 있기 때문에 선택적인 항생제나 다른 원인으로 인하여 식습관을 반영하지 못하는 경우가 발생하기도 합니다.<br><br>

				따라서 장내 미생물 검사시 최근 투약한 이력을 잘 파악하는 것이 중요합니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>20</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_entero.png" alt="장유형"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="data_entero">
        <ul class="data_entero_list">
          <li>
            <strong>Bacteroides</strong>
            <em>박테로이데스</em>
            <span>
            <v-chart :options="reportGenus.Bacteroides" :init-options="{width: 290, height: 180}"/>
          </span>
          </li>
          <li>
            <strong>Prevotella</strong>
            <em>프로보텔라</em>
            <span>
            <v-chart :options="reportGenus.Prevotella" :init-options="{width: 290, height: 180}"/>
          </span>
          </li>
          <li>
            <strong>Ruminococcus</strong>
            <em>루미노코커스</em>
            <span>
            <v-chart :options="reportGenus.Ruminococcus" :init-options="{width: 290, height: 180}"/>
          </span>
          </li>
        </ul>
        <div> [ 각 장유형의 나이별 TREND 와 나의 위치 ]</div>
      </div>
      <div class="data_box">
        <div class="txt_area margintop0">
          EnteroType은 주로 장기적인 식이 취향에 의하여 장 미생물의 우점종이 변경되는 경우 입니다.<br><br>

          하지만 가끔 항생제를 복용한 후에 프리보텔라만 상대적으로 작아지는 경우도 있기 때문에 선택적인 항생제나 다른 원인으로 인하여 식습관을 반영하지 못하는 경우가 발생하기도 합니다.<br><br>

          따라서 장내 미생물 검사시 최근 투약한 이력을 잘 파악하는 것이 중요합니다.
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>21</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <section class="cover">
      <div><img src="img/report_information2.jpg"></div>
    </section>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_lactobacilli.png" alt="유산균 분석"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="data_box data_box_graph">
        <h3>Bifidobacterium</h3>
        <div class="graph_area_diversity">
          <v-chart :options="bifiSpeciesChart" :initOptions="{ height: '400px', width: '1038px'}"/>
        </div>
        <h3>Lactobacillus</h3>
        <div class="graph_area_diversity">
          <v-chart :options="lactoSpeciesChart" :initOptions="{ height: '400px', width: '1038px'}"/>
        </div>

        <div class="txt_area">
          대표적인 유산균인 비피더스균과 락토바실러스의 세부종의 분포를 분석합니다.<br><br>
          <span class="mint">
				고객님의 경우 비피더스보다는 락토바실러스가 부족한것으로 보여집니다.<br>
				락토바실러스는 적절한 처방을 통해  다양한 방법으로 섭취할 수 있습니다.
			</span>
        </div>
      </div>
      <div class="tip_box">
        <div>
				<span>
				대표적 두 가지 유산균인 락토바실러스와 비피더스는 각각 소장과 대장을 책임지는 건강의 파수꾼입니다. 락토바실러스는 <br>면역과 항균효과에 특성이 있으며 비피더스는 대장의 면역 및 장 운동 및 배변에 도움이 된다고 알려져 있습니다.<br>
				이런 유익균은 프로바이오틱스라 칭하고  프로바이오틱스의 먹이를 프리바이오틱스, 프로바이오틱스의 대사산물을 <br> 포스트바이오틱스라고 합니다. 모두 다 자기 역할이 있는 겁니다.<br><br>

				유산균은 장이란 밭에 뿌려지는 씨앗과 같습니다. 좋은 씨앗이 물론 중요하지만 우선 자신한테 잘 맞아야 하고, 또 좋은 비료와 양분이 필요합니다.
				비료와 양분이 되는 것이 프리바이오틱스와 음식입니다.<br>
				그리고 마지막으로 필요한 물과 햇빛은 운동과 생활 습관입니다. 이 중 하나라도 부족하면 씨앗은 그저 씨앗일 뿐 열매가 되지 못하는 이치와 같습니다. 유산균만 먹는다고 장이 좋아지고 변비가 해결되기 어렵습니다.
				</span>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>22</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->


    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_microbiome.png" alt="당신의 장건강에 미치는 역할"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="gut_part">
        <ul>
          <li class="gut_part_graph">
            <v-chart :options="species.barLineChart.gut" :init-options="{width: 519, height: 398}" theme='shine' />
          </li>
          <li class="gut_part_graph">
            <v-chart :options="species.indexChart.gut" :init-options="{width: 519, height: 398}" theme='shine' />
          </li>
        </ul>
      </div>
      <div class="vuetable">
        <vuetable ref="gut1"
                  :fields="species.fields"
                  :api-mode="false"
                  :per-page=10
                  :css="css.table"
                  :row-class="species.onRowClass"
                  :sortOrder="species.sortOrderForGut"
                  :data-manager="dataManager"
        ></vuetable>
      </div>
      <div class="data_box">
        <div class="txt_area" style="margin-top:0px">
          <strong class="gut_title_gut">장 건강</strong>
          장 건강과 관련이 있는 50여종의 균들의 분포를 분석한 결과입니다.<br><br>
          수 많은 장내 미생물 중에서 장에 유익함을 입증하는 연구의 내용에 자주 등장하는 균들입니다. 이 균들은 부티레이트를 생성하거나 염증을 억제하고 혹은 전분이나 지방 등을 분해하는 능력이 있는 균들입니다.<br><br>
          또한 병원체로서의 역할을 하거나 병에 걸린 사람들에게서 공통적으로 많은 균들을 유해균으로 지목하고 있습니다. 하지만 많은 연구에도 불구하고 사람마다 제각기 다른 상황 때문에 이 결과를 질병과 직접적으로 연관 지을 수는 없습니다. 이 검사를 다시 할 경우에 몸의 상태와 세부 균종의 변화를 측정하면 새로운 진단의 방법이 될 수도 있습니다.

        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>22</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <div class="header">
      <h2><img src="img/title_microbiome_immunity.png" alt="당신의 면역에 미치는 역할"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="gut_part">
        <ul>
          <li class="gut_part_graph">
            <v-chart :options="species.barLineChart.immunity" :init-options="{width: 519, height: 398}" theme='shine' />
          </li>
          <li class="gut_part_graph">
            <v-chart :options="species.indexChart.immunity" :init-options="{width: 519, height: 398}" theme='shine' />
          </li>
        </ul>
      </div>
      <div class="vuetable">
        <div class="vuetable">
          <vuetable ref="immunity1"
                    :fields="species.fields"
                    :api-mode="false"
                    :per-page=10
                    :css="css.table"
                    :row-class="species.onRowClass"
                    :sortOrder="species.sortOrderForImmunity"
                    :data-manager="dataManager"
          ></vuetable>
        </div>
      </div>
      <div class="data_box">
        <div class="txt_area">
          <strong class="gut_title_immunity">면역</strong>
          장은 인체의 면역 세포의 70%가 존재하는 면역력에 아주 중요한 장기입니다.<br><br>

          장의 면역력에 도움이 되는 균들은 상당히 많은 균들이 장건강과 관련이 있기 때문에 장건강에 도움이 되는 균과 중복되기도 합니다. 대표적인 <br>면역증강균인 아커만시아나 락토바실러스는 주로 소장에서 면역력 증가에 도움을 줍니다.<br><br>

          반대로 면역력을 저하시키는 유해균들은 장벽에서 장세포를 느슨하게 하여 장의 장벽 역할을 저해하고 병원체로서 여러 질병의 원인이 되기도<br> 합니다.
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>24</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
<!--    <div class="header">
      <h2><img src="img/title_microbiome_mellitus.png" alt="당신의 당뇨/비만에 미치는 역할"><span class="miso"></span></h2>
    </div>
    <section>
      <div class="gut_part">
        <ul>
          <li class="gut_part_graph">
            <v-chart :options="species.barLineChart.obesity" :init-options="{width: 519, height: 398}" theme='shine' />
          </li>
          <li class="gut_part_graph">
            <v-chart :options="species.indexChart.obesity" :init-options="{width: 519, height: 398}" theme='shine' />
          </li>
        </ul>
      </div>
      <div class="vuetable">
        <vuetable ref="obesity1"
                  :fields="species.fields"
                  :api-mode="false"
                  :per-page=8
                  :css="css.table"
                  :row-class="species.onRowClass"
                  :sortOrder="species.sortOrderForObesity"
                  :data-manager="dataManager"
        ></vuetable>
      </div>
      <div class="data_box">
        <div class="txt_area">
          <strong class="gut_title_diabetes">당뇨/비만</strong>
          연령별로 각 장의 유형을  분류해보면 프리보텔라의 나이별 증가  TREND 가 뚜렷하게 나타납니다.<br>
          나이가 들면서 식이 섬유의 섭취가 증가 되는 한국인의 식생활 패턴이 보여집니다.<br><br>
          장유형은 장기적인 식이 습관이  가장 중요한 요소라고 연구된 결과가 있습니다.<br>
          일시적으로 식사 패턴을 바꾸면 일시적으로 장유형이 식이 에 따라 변화 하지만 원래대로 돌아오면 장패턴 역시 금방 원복 되는 결과 역시 보고되었습니다.<br><br>
          이 패턴이 건강의 절대적인 척도는 아닙니다. 프리보텔라균이 채식인에게 주로 나타나고 , 유익한 단쇄지방산을 생산하지만 박테로이데스에서도 단쇄지방산은 생산이 됩니다.<br><br>
          다만 식이 섬유의 섭취가 작을때는 변비가 생길 수 있고 또한 식이섬유는 다양한 유익균의 먹이가 되기때문에 식이섬유의 섭취정도를 대변하는 프리보텔라의 양이 작을때는 장기적인 관점에서 개선할 필요성을 조언할수 있습니다.<br><br><br>
        </div>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>26</span></li>
      </ul>
    </div>
    &lt;!&ndash; 페이지끝 &ndash;&gt;-->


    <!-- 페이지시작 -->
    <div class="header blind">
      <h2>별그래프</h2>
    </div>
    <section>
      <div class="star_graph">
        <v-chart :options="starChart" :initOptions="{
            width: 1100, height : 500 }" theme='shine'/>
      </div>
      <div class="tip_box txt_summary">
        <strong>Summary</strong>
        <p v-html="sample.summary"></p>
      </div>
    </section>
    <div class="footer">
      <ul>
        <li><img src="img/footer_logo.png" alt=""></li>
        <li><span>28</span></li>
      </ul>
    </div>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <section class="cover">
      <div><img src="img/report_information1_adult.jpg"></div>
    </section>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <section class="full">
    </section>
    <!-- 페이지끝 -->

    <!-- 페이지시작 -->
    <section class="full">
      <div class="back_logo"></div>
      <div class="bo_homepage">www.aibiotics.kr</div>
    </section>
    <!-- 페이지끝 -->
  </div>
</template>
// striped
<script>
import Vuetable from 'vuetable-2'
import { mapActions } from 'vuex'
import moment from 'moment'
import CssConfig from '../theme/vueTableCssBootstrap4.js'
import FoodTable from './FoodTable'

export default {
  components: {
    Vuetable,
    FoodTable,
  },
  name: 'report',
  data () {
    return {
      bIndexStr: '',
      bIndexClass: '',
      moment: moment,
      sample: {},
      css: CssConfig,
      balance: {},
      starChart: {},
      species: {},
      survey: {},
      sleep: {},
      heightChartData: {},
      tastesList: {},
      aibChart1: {},
      aibChart2: {},
      byAgeDiversity: {},
      freqDistributionListChart: {},
      phylum: {},
      reportPhylum: {},
      reportGenus: {},
      enterotypePieChart: {},
      balanceGauge: {
        size: {
          width: 400,
          height: 400,
        },
        gut: {},
      },
      phylumScatter: {},
      bifiSpeciesChart: {},
      lactoSpeciesChart: {},
    }
  },
  methods: {
    ...mapActions(['speciesAction', 'getSample', 'getPhylumChartData', 'lactoDetail', 'getBalanceChartDataForReport']),
    dataManager (s, p) {
      console.log('key ', s[0].key)
      let tableData = {}
      try {
        tableData = this.species.tableData[s[0].key]
      } catch (e) {
        return tableData
      }
      if (s.length > 0) {
        //console.log('#report dataManager s', JSON.stringify(s) );
        //console.log('#report dataManager p', JSON.stringify(p) );

        tableData.sort(function (a, b) {
          let bindoA = parseInt(a.bindo)
          let bindoB = parseInt(b.bindo)

          //빈도 수 정렬
          if (s[0].direction === 'desc') {
            return bindoA > bindoB ? -1 : bindoA < bindoB ? 1 : 0
          }

          return bindoA < bindoB ? -1 : bindoA > bindoB ? 1 : 0
        })
        console.log(p.per_page)
        // 10개 이상이면 Row 제어
        if (p.per_page >= 7) {
          tableData = tableData.slice(0, p.per_page)
        }
      }

      console.log('tableData ', tableData)
      return {
        pagination: p,
        data: tableData,
      }
    },
    balanceChart () {
      Object.keys(this.balance).reduce((acc, key) => {
        this.balance[key].series[0].detail.formatter = ' '
        //balance[key].series[1].detail.formatter = " ";
        if(key === 'totalBalanceGaugeData') this.balance[key].series[1].detail.formatter = " ";
        let centerPos = (key === 'totalBalanceGaugeData') ? '60%' : '75%'
        this.balance[key].series[0].center[1] = centerPos
        this.balance[key].series[1].center[1] = centerPos
        delete this.balance[key].toolbox
      }, {})

      //this.$store.commit("setBalance", balance);
    },
    async getReportSample () {
      await this.getSample()
      this.sample = this.$store.state.sample
    },
    async getBalanceIndex () {
      let len = this.starChart.adjustedStarScore.length
      let bIndex = this.starChart.adjustedStarScore.reduce((acc, obj, index) => {
        acc = acc + obj
        if (len === (index) + 1) return acc / len
        return acc
      }, 0)

      console.log('get bindex !!! ', bIndex)

      switch (true) {
        case (bIndex > 8.5):
          this.bIndexStr = '우수'
          this.bIndexClass = 'score_a'
          break
        case (bIndex > 6.5 && bIndex < 8.5):
          this.bIndexStr = '양호'
          this.bIndexClass = 'score_b'
          break
        case (bIndex > 4.5 && bIndex < 6.5):
          this.bIndexStr = '주의'
          this.bIndexClass = 'score_c'
          break
        case (bIndex > 3 && bIndex < 3.5):
          this.bIndexStr = '경고'
          this.bIndexClass = 'score_d'
          break
        default:
          this.bIndexStr = '위험'
          this.bIndexClass = 'score_e'
          break
      }

    },
    getReportData () {
      this.getBalanceChartDataForReport().then( balance => {
        this.balance = balance;
      })
      this.starChart = this.$store.state.starChart.bgChart
      this.species = this.$store.state.species
      this.survey = this.$store.state.survey
      this.sleep = this.$store.state.sleepChartData
      this.heightChartData = this.$store.state.heightChartData
      this.tastesList = this.$store.state.tastesList
      this.aibChart1 = this.$store.state.aibChart1
      this.aibChart2 = this.$store.state.aibChart2
      this.byAgeDiversity = this.$store.state.byAgeDiversity
      this.freqDistributionListChart = this.$store.state.freqDistributionListChart
      this.phylum = this.$store.state.phylum
      this.enterotypePieChart = this.$store.state.enterotypePieChart
    },
    //species tables
    reloadTable () {
      try {
        Object.keys(this.species.tableData).forEach(key => {
          this.$refs[(key + 1)].reload()
          this.$refs[(key + 2)].reload()
        })
      } catch (e) {
        console.log(e)
        return
      }
    },
  },
  async mounted () {
    console.log('#report mounted')
    this.getReportData()

    await this.getReportSample()
    await this.getBalanceIndex()
    //await this.speciesAction(); //home > species 에서 호출
    this.$store.state.focusPageNm = 'report'
    //나이별 다양성
    await this.getPhylumChartData('phylum')
    await this.getPhylumChartData('genus')
    this.reportPhylum = this.$store.state.reportPhylum
    this.reportGenus = this.$store.state.reportGenus

    //유산균
    let lactoDetailData = await this.lactoDetail()
    this.bifiSpeciesChart = lactoDetailData.speciesDetail.bifi
    this.lactoSpeciesChart = lactoDetailData.speciesDetail.lacto

    this.reloadTable()
    //this.balanceChart()
  },
  created () {
  },
  updated () {
    this.$nextTick(function () {
      let divPage = document.querySelectorAll('div.footer>ul>li>span')
      for (let i = 0; i < divPage.length; i++) {
        divPage[i].innerText = i + 1
      }
    })
  },
  computed : {
    aibTotal(){
      return this.$store.state.aibTotal;
    },
    weightChartData() {
      return this.$store.state.weightChartData;
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../theme/reportScss';
</style>